import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

import styles from "./LanguageBrowser.module.css";

const LanguageBrowser = ({
    data,
    onSearch,
    onChecked
}) => {
    const [search, setSearch] = React.useState('');

    const datas = !data ? [] : Object.entries(data).map(([k,v]) => ({locale: k, ...v}))
        .filter(l => (
            !search.trim() || l.locale.toLowerCase().startsWith(search.toLowerCase()) ||
                (l.label && l.label.toLowerCase().startsWith(search.toLowerCase())) ||
                (onSearch && onSearch(search, l))
        ))
        // Alphabetical Sort
        .sort((a, b) => {
            const aLabel = a.label ? a.label : a.locale;
            const bLabel = b.label ? b.label : b.label;
            return (
                aLabel < bLabel ? -1 : (aLabel > bLabel ? 1 : 0)
            );
        })
        // Selected Languages Sort (Selected first)
        .sort((a, b) => {
            if(a.checked)
                return b.checked ? 0 : -1;
            return b.checked ? 1 : 0;
        });

    return (
        <div className={styles.languageBrowser}>
            <TextField
                className={styles.search}
                id="outlined-read-only-input"
                label="Rechercher une langue"
                variant="outlined"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <div className={styles.content}>
                {
                    datas.map(d => (
                        <span 
                            key={`browser-langue-${d.locale}`} 
                            className={`${styles.contentItem} ${d.checked ? styles.checked : ''}`}
                            onClick={() => onChecked && onChecked(d)}
                            title={d.locale}
                        >
                            <label>{ d.supported === false ? '*' : '' }{ d.label ? d.label : d.locale }</label>
                        </span>
                    ))
                }
            </div>
        </div>
    );
};

export default LanguageBrowser;