export function forceCopy(text) {
    const elem = document.createElement('input');
    elem.setAttribute('value', text);
    elem.style.opacity = 0;
    elem.style.position = 'fixed';
    elem.style.top = '0';
    elem.style.left = '0';

    const e = document.body.appendChild(elem);
    e.value = text;
    e.select();
    document.execCommand('copy');
    document.body.removeChild(e);
}