import React, {useEffect, useRef, useState} from 'react';
import Alert from "../../components/Alert/Alert";
import { useParams } from 'react-router-dom';
import UserService from '../../http-services/user.service';
import LicenseService from '../../http-services/license.service';
import CounterService from '../../http-services/counter.service';
import BackdropLoader from "../../components/BackdropLoader/Backdrop";
import {Form, Field} from 'react-final-form';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {default as MaterialTable} from "../../components/MaterialTable/MaterialTable";
import moment from 'moment';
import Box from "@material-ui/core/Box";
import {TextField, Select, MenuItem, FormControl, InputLabel, Typography, Grid} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { formatSeconds } from '../../utils/common';
import makeStyles from "@material-ui/core/styles/makeStyles";
import microsoftService from '../../http-services/microsoft.service';
import LanguageBrowser from '../../components/LanguageBrowser/LanguageBrowser';
import languagesService from '../../http-services/languages.service';
import HygiaEdit, { HygiaMode } from '../../components/HygiaEdit/HygiaEdit';
import hygiaService from '../../http-services/hygia.service';

const useStyles = makeStyles({
    mb_25: {
        marginBottom: '25px',
    },
    mb_50: {
        marginBottom: '50px',
    },
    btn:{
        margin: '15px 0',
        backgroundColor: '#cfdde4',
        "&:hover":{
            backgroundColor: '#7da0b0'
        }
    },
    btn_clipboard:{
        margin: '10px',
        backgroundColor: '#cfdde4',
        "&:hover":{
            backgroundColor: '#7da0b0'
        }
    },
    w_100:{
        width:'100%'
    },
    hygiaWrapper: {
        marginTop: '10px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        "& > *": {
            width: '100%'
        }
    },
    hygiaEditorWrapper: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        zIndex: '99',
        marginTop: '-4px'
    }

});

const ShowUser = (props) => {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [licensesItems, setLicensesItems] = useState([]);
    const [languages, setLanguages] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const tokenInputRef = useRef(null);
    const {id} = useParams();

    const [selectedHygia, setSelectedHygia] = useState(null);
    const [loadingHygiaEditor, setLoadingHygiaEditor] = useState(false);
    const [hygiaUpdateMode, setHygiaUpdateMode] = useState(HygiaMode.NONE);
    
    const updateUserHygias = () => {
        return new Promise(async resolve => {
            setLoadingHygiaEditor(true);

            UserService.getHygias(user._id)
            .then(newHygias => {
                setUser({
                    ...user,
                    hygias: [...newHygias.data]
                });
                if(!selectedHygia) return;

                for(const hygia of newHygias.data)
                    if(hygia._id == selectedHygia._id) return setSelectedHygia({...hygia});
                setSelectedHygia(null);
            }).catch(err => {
                setAlert({ severity: 'error', message: err.toString() });
            }).finally(() => {setLoadingHygiaEditor(false); resolve();});
        });
    };
    
    const [alert, setAlert] = useState({});
    const close = () =>{
        setAlert({...alert, open: false})
    };

    const onUserSubmit = async (values,form) => {
        setLoading(true);
        UserService.updateUser(values)
        .then(res=>{
            setAlert({ severity: 'success', message: "Utilisateur modifié", open: true });
            setLoading(false);
            form.change('password',undefined);
            
        })
        .catch(error => {
            if (error.response)
                setAlert({ severity: 'error', message: error.response.data.message, open: true });
            else
                setAlert({ severity: 'error', message: error.toString() });
            setLoading(false);
        });
    };

    const onPreferencesSubmit = async (values,form) => {
        setLoading(true);
        UserService.updatePreferences(user._id, values)
        .then(res=>{
            setAlert({ severity: 'success', message: "Préférences modifié", open: true });
            setLoading(false);
        })
        .catch(error => {
            if (error.response)
                setAlert({ severity: 'error', message: error.response.data.message, open: true });
            else
                setAlert({ severity: 'error', message: error.toString() });
            setLoading(false);
        });
    };

    const onLanguagesSubmit = async(values, form) => {
        setLoading(true);

        UserService.updateLanguages(
            user._id, 
            {
                languages: Object.entries(languages).filter(([,v]) => v.checked).map(([k]) => k).join(';')
            }
        )
        .then(res=>{
            setAlert({ severity: 'success', message: "Langues modifié", open: true });
            setLoading(false);
        })
        .catch(error => {
            if (error.response)
                setAlert({ severity: 'error', message: error.response.data.message, open: true });
            else
                setAlert({ severity: 'error', message: error.toString() });
            setLoading(false);
        });
    };

    const onLicenseSubmit = async (values,form) => {
        setLoading(true);
        UserService.addLicense({license:values.license,user:user._id})
        .then(res => {
            setAlert({ severity: 'success', message: "Utilisateur modifié", open: true });
            setUser(res.data);
            form.change('license',undefined);
            setLoading(false);
        })
        .catch(error => {
            if (error.response)
                setAlert({ severity: 'error', message: error.response.data.message, open: true });
            else
                setAlert({ severity: 'error', message: error.toString(), open: true });
            setLoading(false);
        });    
    };

    useEffect( () =>{
        setLoading(true);
        
        Promise.all([UserService.getUser(id),LicenseService.getLicenses(),languagesService.getSupportedLanguages()])
        .then((values) =>{
            // Process user data
            const userData = values[0].data;
            if(userData.roles) {
                userData.roles.forEach(role => {
                    if(!role) return;
                    if(role.name == 'reviser')
                        userData.role_reviser = true;
                    if(role.name == 'chat')
                        userData.role_chat = true;
                    if(role.name == 'web')
                        userData.role_web = true;
                    if(role.name == 'words')
                        userData.role_words = true;
                    if(role.name == 'file')
                        userData.role_file = true;
                    if(role.name == 'diarization')
                        userData.role_diarization = true;
                });
            }
            setUser(userData);
            setLicensesItems(values[1].data);
            setLanguages((() => {
                const outLang = {};

                if(values[2].data && typeof values[2].data === 'object' && values[2].data instanceof Array) {
                    values[2].data/*.filter(lang => lang.supported)*/.sort((a, b) => {
                        const aLabel = a.label ? a.label : a.locale;
                        const bLabel = b.label ? b.label : b.label;
                        return (
                            aLabel < bLabel ? -1 : (aLabel > bLabel ? 1 : 0)
                        );
                    }).forEach(lang => {
                        outLang[lang.locale] = {
                            label: (lang.supported === false ? '*' : '') + lang.label,
                            checked: userData.allowedLanguages.includes(lang.locale)
                        };
                    });
                }

                return outLang;
            })());
            setLoading(false);
        })
        .catch((reason) =>{
            setAlert({ severity: 'error', message: String(reason), open: true });
            setLoading(false);
        });
    },[]);


    const columns = [
        { field: 'created_at', title: "Date de création", minWidth:120, render:(props)=> moment(props.created_at).format('DD/MM/Y')  },
        { field: 'license.title', title: 'Nom', minWidth: 120 },
        { field: 'license.quota', title: 'Quota', minWidth: 120, render: (props) => formatSeconds(props.license.quota,['H','m'])},
        { field: 'solde', title: "solde", minWidth:120,render: (props) => formatSeconds(props.solde,['H','m']) },
    ];

    const hygiaColumns = [
        { field: 'title', title: "Titre", minWidth:120  },
        { field: 'created_at', title: "Date de création", minWidth:120, render:(props)=> moment(props.created_at).format('DD/MM/Y')  },
        { field: 'sessions', title: 'Nb.sessions', minWidth: 120, render: props => (props.sessions ? props.sessions.length : 0) },
    ];

    const renderLicensesItems = ({input}) =>{
        return (
            <FormControl fullWidth>
                <InputLabel id="select-license">
                    Sélectionner une license
                </InputLabel>
                <Select required fullWidth id="select-license" labelId="select-license" name={input.name}  value={input.value} onChange={input.onChange}>
                    {
                        licensesItems.map((item)=>
                            <MenuItem value={item._id}>{item.title} - {formatSeconds(item.quota,['H','m'])}</MenuItem>
                        )
                    }  
                </Select>
            </FormControl>
        )       
    };

    const renderLanguageItems = (title,props) => {
        return (
            <FormControl fullWidth>
                <InputLabel id={"select-language_"+ props.name}>
                    {title}
                </InputLabel>
                <Select 
                    fullWidth id={"select-language_"+props.name} 
                    labelId={"select-language_"+props.name} 
                    name={props.input.name} 
                    value={props.input.value} 
                    onChange={props.input.onChange}
                >
                    <MenuItem value="">Aucune</MenuItem>
                    {
                        Object.entries(languages).filter(([,v]) => v.checked).map(([k, v]) => (
                            <MenuItem value={k}>{v.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )        
    };

    function copyToClipboard(e) {
        e.preventDefault();
        tokenInputRef.current.select();
        document.execCommand('copy');
        setCopySuccess('Copié');
        setTimeout(() => {
            setCopySuccess('');
        }, 3000);
    };

    const onDownloadConso = () => {
        UserService.downloadConso(id).then((response) => {
            console.log('Result', response.data);
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}-conso.json`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err)=>{
            setAlert({ severity: 'error', message: 'Le fichier n\'existe pas.', open: true });
          });
    };

    const onHygiaDownloadConso = () => {
        if(!selectedHygia) return;
        hygiaService.downloadConso(selectedHygia._id).then((response) => {
            console.log('Result', response.data);
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `hygia-${id}-conso.json`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err)=>{
            setAlert({ severity: 'error', message: 'Le fichier n\'existe pas.', open: true });
          });
    };

    const classes = useStyles();
    return (
        <div>
           
            <Alert alert={alert} close={close}/>
            <BackdropLoader open={loading}/>
            {
            user && 
                <div>
                    <Typography variant="h5" gutterBottom>
                        Informations de l'utilisateur
                    </Typography>
                    <Divider light /> 
                   <Form
                    onSubmit={onUserSubmit}
                    initialValues={user}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} className={classes.mb_50}>
                            <Box p={1}>
                                <Field name="last_name" placeholder={"Nom"} label={"Nom"}>
                                    {props => <TextField variant="outlined" fullWidth {...props} {...props.input}/>}
                                </Field>
                            </Box>
                            <Box p={1}>
                                <Field name="first_name" placeholder={"Prénom"} label={"Prénom"}>
                                    {props => <TextField variant="outlined" fullWidth {...props} {...props.input}/>}
                                </Field>
                            </Box>
                            <Box p={1}>
                                <Field name="email" placeholder={"E-mail"} label={"E-mail"}>
                                    {props => <TextField variant="outlined" fullWidth {...props} {...props.input}/>}
                                </Field>
                            </Box>

                            <Box p={1}>
                                <Field name="password" placeholder={"Mot de passe"} label={"Mot de passe"}>
                                    {props => <TextField variant="outlined" fullWidth {...props} {...props.input}/>}
                                </Field>
                            </Box>

                            <Box p={1}>
                                <FormControlLabel control={
                                    <Field name="role_reviser" type="checkbox" id="role_reviser" label="Role correcteur">
                                        {props => (
                                            <Checkbox {...props.input}/>
                                        )}
                                    </Field>
                                } label="Role correcteur" />
                            </Box>

                            <Box p={1}>
                                <FormControlLabel control={
                                    <Field name="role_chat" type="checkbox" id="role_chat" label="Role chat">
                                        {props => (
                                            <Checkbox {...props.input}/>
                                        )}
                                    </Field>
                                } label="Role chat" />
                            </Box>

                            <Box p={1}>
                                <FormControlLabel control={
                                    <Field name="role_web" type="checkbox" id="role_web" label="Role web">
                                        {props => (
                                            <Checkbox {...props.input}/>
                                        )}
                                    </Field>
                                } label="Role web" />
                            </Box>

                            <Box p={1}>
                                <FormControlLabel control={
                                    <Field name="role_words" type="checkbox" id="role_words" label="role_words web">
                                        {props => (
                                            <Checkbox {...props.input}/>
                                        )}
                                    </Field>
                                } label="Role dictionnaire" />
                            </Box>

                            <Box p={1}>
                                <FormControlLabel control={
                                    <Field name="role_file" type="checkbox" id="role_file" label="role_file web">
                                        {props => (
                                            <Checkbox {...props.input}/>
                                        )}
                                    </Field>
                                } label="Role fichier" />
                            </Box>

                            <Box p={1}>
                                <FormControlLabel control={
                                    <Field name="role_diarization" type="checkbox" id="role_diarization" label="role_diarization web">
                                        {props => (
                                            <Checkbox {...props.input}/>
                                        )}
                                    </Field>
                                } label="Role diarisation" />
                            </Box>
                            <Button disabled={submitting ? "disabled" : ""} fullWidth type="submit" className={`shadow-2 mb-4 ${classes.btn}`}>Modifier l'utilisateur</Button>
                            {/* <Divider variant="middle"/> */}
                        </form>
                    )}>
                   </Form>

                   <Typography variant="h5" gutterBottom>
                        Consommation de l'utilisateur
                    </Typography>
                    <Divider light /> 

                   <Button fullWidth type="button" className={`shadow-2 mb-4 ${classes.btn}`} onClick={onDownloadConso}>Télécharger la consommation</Button>

                   <Typography variant="h5" gutterBottom>
                        Préférences de l'utilisateur
                    </Typography>

                    <Divider light /> 

                   <Form
                    onSubmit={onPreferencesSubmit}
                    initialValues={user.preferences ? user.preferences : {}}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} className={classes.mb_50}>
                            <Box p={1}>
                                <Field name="nickname" placeholder={"Nom d'affichage"} label={"Nom d'affichage"}>
                                    {props => <TextField variant="outlined" fullWidth {...props} {...props.input}/>}
                                </Field>
                            </Box>

                            
                            <Grid container>
                                <Grid item xs={8}>
                                    <Box p={1} className={classes.mb_25}>
                                        <Field name="language" label={"Language"}>
                                            {props => 
                                                renderLanguageItems("Selectionner langue parlée", props)
                                            }
                                        </Field>
                                    </Box>
                                </Grid>

                                { user.auto_start_token &&
                                    <Grid container>
                                         <Grid item xs={8}>
                                     <TextField
                                         className={classes.w_100}
                                         inputRef={tokenInputRef}
                                         id="outlined-read-only-input"
                                         label="Token utilisateur"
                                         defaultValue={user.auto_start_token}
                                         InputProps={{
                                             readOnly: true,
                                         }}
                                         variant="outlined"
                                     />
                                 </Grid>
                                  <Grid item xs={4}>
                                       <div>
                                         <Button className={`shadow-2 mb-4 ${classes.btn_clipboard}`} onClick={copyToClipboard}>Copier le token</Button>
                                         {copySuccess}    
                                     </div>
                                  </Grid>
                                </Grid>
                                }
                            </Grid>
                           
                            <Button disabled={submitting ? "disabled" : ""} fullWidth type="submit" className={`shadow-2 mb-4 ${classes.btn}`}>Modifier les préférences</Button>
                            {/* <Divider variant="middle"/> */}
                        </form>
                    )}>
                   </Form>

                   <Typography variant="h5" gutterBottom>
                        Langues
                    </Typography>

                    <Divider light />

                    <Form
                        onSubmit={onLanguagesSubmit}
                        render={({ handleSubmit, form, submitting, pristine, values }) =>(
                            <form onSubmit={handleSubmit} className={classes.mb_25}>
                                <Box p={1}>
                                    <LanguageBrowser
                                        data={languages}
                                        onChecked={d => {
                                            setLanguages(state => {
                                                state[d.locale] = {
                                                    ...state[d.locale],
                                                    checked: !d.checked
                                                };
                                                return { ...state };
                                            });
                                        }}
                                    />
                                </Box>
                                <Button disabled={submitting ? "disabled" : ""} fullWidth type="submit" className={`shadow-2 mb-4 ${classes.btn}`}>Mettre à jour les langues</Button>
                            </form>
                        )}>
                        
                    </Form>
                
                   <Typography variant="h5" gutterBottom>
                        Licences
                    </Typography>

                    <Divider light/>
                
                    <Form
                        onSubmit={onLicenseSubmit}
                        render={({ handleSubmit, form, submitting, pristine, values }) =>(
                            <form onSubmit={handleSubmit} className={classes.mb_25}>
                            <Box p={1}>
                                <Field name="license" label={"License"}>
                                    {props => 
                                    renderLicensesItems(props)
                                    }
                                </Field>
                            </Box>
                            <Button disabled={submitting ? "disabled" : ""} fullWidth type="submit" className={`shadow-2 mb-4 ${classes.btn}`}>Ajouter la licence</Button>
                            <Divider variant="middle"/>
                            </form>
                        )}>
                        
                    </Form>

                   <div>
                       <MaterialTable
                        columns={columns}
                        data={user.licenses}
                        title={"Licences utilisateur"}
                        />
                   </div>

                   <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
                        Guichets de l'utilisateur
                    </Typography>

                    <Divider light/>

                    <div className={classes.hygiaWrapper}>
                       <MaterialTable
                            onRowClick={(e, data) => {
                                setSelectedHygia(data);
                                setHygiaUpdateMode(HygiaMode.UPDATE);
                            }}
                            columns={hygiaColumns}
                            data={user.hygias ? user.hygias : []}
                            title={"Guichets de l'utilisateur"}
                            editable={{
                                onRowDelete: (oldData) => new Promise((resolve) => {
                                    setLoadingHygiaEditor(true);
                                    hygiaService.deleteHygia(oldData._id)
                                    .then(result => {
                                    }).catch(reason => {
                                        console.log('Reason', reason);
                                        setAlert({ severity: 'error', message: `Something went wrong while deleting the hygia`, open: true });
                                    }).finally(() => updateUserHygias().then(() => resolve()))
                                })
                            }}
                        />

                        <Button 
                            fullWidth 
                            type="button" 
                            className={`shadow-2 mb-4 ${classes.btn}`} 
                            onClick={() => {
                                setHygiaUpdateMode(HygiaMode.CREATE);
                                setSelectedHygia(null);
                            }}
                        >
                            Créer un guichet
                        </Button>

                        <div className={classes.hygiaEditorWrapper}>
                            <HygiaEdit 
                                data={selectedHygia}
                                loading={loadingHygiaEditor}
                                mode={hygiaUpdateMode}
                                onClose={() => { 
                                    setSelectedHygia(null);
                                    setHygiaUpdateMode(HygiaMode.NONE);
                                }}
                                allowedLanguages={(() => {
                                    const out = {};
                                    Object.entries(languages).filter(([,v]) => v.checked).forEach(([k,v]) => out[k] = v.label ? v.label : k);
                                    return out;
                                })()}
                                onDownloadConso={() => onHygiaDownloadConso()}
                                onUpdate={data => {
                                    if(hygiaUpdateMode !== HygiaMode.UPDATE) {
                                        setLoadingHygiaEditor(true);
                                        hygiaService.createHygia({
                                            userId: user._id,
                                            agent_nickname: data.agentNickname,
                                            agent_lang: data.agentLang,
                                            guest_nickname: data.guestNickname,
                                            user_nickname: data.userNickname,
                                            title: data.title
                                        }).then(result => {
                                            updateUserHygias().then(() => {
                                                setSelectedHygia(null);
                                                setHygiaUpdateMode(HygiaMode.NONE);
                                            });
                                        }).catch(reason => {
                                            setLoadingHygiaEditor(false);
                                            console.log('Reason', reason);
                                            setAlert({ severity: 'error', message: `Something went wrong while creating the hygia`, open: true });
                                        });
                                        return;
                                    }

                                    if(!selectedHygia) return;

                                    setLoadingHygiaEditor(true);
                                    hygiaService.updateHygia(selectedHygia._id, {
                                        agent_nickname: data.agentNickname,
                                        agent_lang: data.agentLang,
                                        guest_nickname: data.guestNickname,
                                        user_nickname: data.userNickname,
                                        title: data.title
                                    }).then(result => {
                                        console.log('Success', result);
                                    }).catch(reason => {
                                        console.log('Reason', reason);
                                        setAlert({ severity: 'error', message: `Something went wrong while creating the hygia`, open: true });
                                    }).finally(() => updateUserHygias());
                                }}
                                onResetTokens={() => {
                                    if(!selectedHygia) return;
                                    setLoadingHygiaEditor(true);
                                    hygiaService.resetHygiaTokens(
                                        selectedHygia._id
                                    ).then(result => {
                                        console.log('Success', result);
                                    }).catch(reason => {
                                        console.log('Reason', reason);
                                        setAlert({ severity: 'error', message: `Something went wrong while reseting the hygia's tokens`, open: true });
                                    }).finally(() => updateUserHygias());
                                }}
                            />
                        </div>
                   </div>
                </div>
            }
            
        </div>
    );
}

export default ShowUser;