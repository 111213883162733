import React from "react";
import BackdropLoader from "../BackdropLoader/Backdrop";
import styles from "./HygiaEdit.module.css";
import CloseIcon from '@material-ui/icons/Close';
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { forceCopy } from "../../utils/copy";

export const HygiaMode = {
    NONE: 'none',
    UPDATE: 'update',
    CREATE: 'create'
};

const HygiaEdit = ({
    loading,
    data,
    onUpdate,
    onClose,
    onResetTokens,
    onDownloadConso,
    allowedLanguages,
    mode
}) => {
    const [ agentNickname, setAgentNickname ] = React.useState('');
    const [ agentToken, setAgentToken ] = React.useState('');
    const [ agentLang, setAgentLang ] = React.useState('');

    const [ guestNickname, setGuestNickname ] = React.useState('');
    const [ guestToken, setGuestToken ] = React.useState('');

    const [ userNickname, setUserNickname ] = React.useState('');
    const [ userToken, setUserToken ] = React.useState('');

    const [ title, setTitle ] = React.useState('');

    const updateStates = (data) => {
        setAgentNickname(data ? data.agent_nickname : '');
        setAgentToken(data ? data.agent_token : '');
        setAgentLang(data ? data.agent_lang : '');
        setGuestNickname(data ? data.guest_nickname : '');
        setGuestToken(data ? data.guest_token : '');
        setUserNickname(data ? data.user_nickname : '');
        setUserToken(data ? data.user_token : '');
        setTitle(data ? data.title : '');
    };

    React.useEffect(() => {
        if(!data || mode !== HygiaMode.UPDATE) return;
        updateStates(data);
    }, [data]);

    React.useEffect(() => {
        if(agentLang != '' && (!allowedLanguages || allowedLanguages[agentLang] === undefined))
            setAgentLang('');
    }, [allowedLanguages]);

    React.useEffect(() => {
        if(mode !== HygiaMode.UPDATE)
            return updateStates(null);
        
        if(data)
            updateStates(data);
    }, [mode]);

    const onCopyClick = (e, content) => {
        if(!e) return;
        e.preventDefault();

        try {
            navigator.clipboard.writeText(content);
        }
        catch(err) {
            forceCopy(content);
        }
    };

    return (
        <>
            { 
                mode !== HygiaMode.NONE && (data || mode !== HygiaMode.UPDATE) && (
                    <BackdropLoader open={loading} absolute={true} className={styles.hygiaLoader} />
                )
            }
            {
                mode !== HygiaMode.NONE && (data || mode !== HygiaMode.UPDATE) && (
                    <div className={styles.hygiaEdit}>
                        <div className={styles.header}>
                            <Typography variant="h6">
                                { mode === HygiaMode.UPDATE ? "Editer un Guichet" : "Créer un Guichet" }
                            </Typography>
                            <IconButton onClick={() => onClose && onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <Divider className={styles.divider} light />

                        <div className={styles.body}>
                            <Box p={1}>
                                <TextField 
                                    variant="outlined" 
                                    fullWidth 
                                    value={title} 
                                    label="Titre du gichet" 
                                    onInput={e => setTitle(e.target.value)} 
                                />
                            </Box>

                            <Typography style={{ marginTop: '20px', marginLeft: '8px' }} variant="subtitle1">
                                Agent
                            </Typography>
                            <Box p={1}>
                                <TextField 
                                    variant="outlined" 
                                    fullWidth 
                                    value={agentNickname} 
                                    label="Surnom de l'agent" 
                                    onInput={e => setAgentNickname(e.target.value)} 
                                />
                            </Box>

                            <Box p={1}>
                                <InputLabel id={"select-language_agent"}>
                                    {"Langue de l'agent"}
                                </InputLabel>
                                <Select 
                                    fullWidth id={"select-language_agent"} 
                                    labelId={"select-language_agent"} 
                                    name={"select-language_agent"} 
                                    value={agentLang} 
                                    onChange={(e) => setAgentLang(e.target.value)}
                                >
                                    <MenuItem value="">Aucune</MenuItem>
                                    {
                                        Object.entries(allowedLanguages).map(([k, v]) => (
                                            <MenuItem value={k}>{v}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Box>

                            <Box p={1} style={{ opacity: mode !== HygiaMode.UPDATE ? 0.25 : undefined }}>
                                <div className={styles.tokenWrapper}>
                                    <TextField
                                         fullWidth
                                         id="outlined-read-only-input"
                                         label="Token d'accès de l'agent"
                                         value={agentToken}
                                         InputProps={{
                                             readOnly: true,
                                         }}
                                         variant="outlined"
                                         disabled={mode !== HygiaMode.UPDATE}
                                     />
                                     <Button 
                                        className={`shadow-2 mb-4 ${styles.btn_clipboard}`} 
                                        onClick={e => onCopyClick(e, agentToken)}
                                        disabled={mode !== HygiaMode.UPDATE}
                                    >
                                        Copier le token
                                    </Button>
                                </div>
                            </Box>

                            <Typography style={{ marginTop: '20px', marginLeft: '8px' }} variant="subtitle1">
                                Invité
                            </Typography>
                            <Box p={1}>
                                <TextField 
                                    variant="outlined" 
                                    fullWidth 
                                    value={guestNickname} 
                                    label="Surnom de l'invité" 
                                    onInput={e => setGuestNickname(e.target.value)}
                                />
                            </Box>

                            <Box p={1} style={{ opacity: mode !== HygiaMode.UPDATE ? 0.25 : undefined }}>
                                <div className={styles.tokenWrapper}>
                                    <TextField
                                         fullWidth
                                         id="outlined-read-only-input"
                                         label="Token d'accès de l'invité"
                                         value={guestToken}
                                         InputProps={{
                                             readOnly: true,
                                         }}
                                         variant="outlined"
                                         disabled={mode !== HygiaMode.UPDATE} 
                                     />
                                     <Button 
                                        className={`shadow-2 mb-4 ${styles.btn_clipboard}`} 
                                        onClick={e => onCopyClick(e, guestToken)}
                                        disabled={mode !== HygiaMode.UPDATE}
                                    >
                                        Copier le token
                                    </Button>
                                </div>
                            </Box>

                            <Typography style={{ marginTop: '20px', marginLeft: '8px' }} variant="subtitle1">
                                Utilisateur téléphone
                            </Typography>
                            <Box p={1}>
                                <TextField 
                                    variant="outlined" 
                                    fullWidth 
                                    value={userNickname} 
                                    label="Surnom de l'utilisateur téléphone" 
                                    onInput={e => setUserNickname(e.target.value)}
                                />
                            </Box>

                            <Box p={1} style={{ opacity: mode !== HygiaMode.UPDATE ? 0.25 : undefined }}>
                                <div className={styles.tokenWrapper}>
                                    <TextField
                                         fullWidth
                                         id="outlined-read-only-input"
                                         label="Token d'accès de l'utilisateur téléphone"
                                         value={userToken}
                                         InputProps={{
                                             readOnly: true,
                                         }}
                                         variant="outlined"
                                         disabled={mode !== HygiaMode.UPDATE} 
                                     />
                                     <Button 
                                        className={`shadow-2 mb-4 ${styles.btn_clipboard}`} 
                                        onClick={e => onCopyClick(e, userToken)}
                                        disabled={mode !== HygiaMode.UPDATE}
                                    >
                                        Copier le token
                                    </Button>
                                </div>
                            </Box>

                            <div className={styles.actionWrapper}>
                                <Button 
                                    fullWidth 
                                    type="button" 
                                    className={`shadow-2 mb-4 ${styles.btn}`} 
                                    onClick={() => {
                                        if(!onUpdate) return;
                                        onUpdate({
                                            agentNickname,
                                            agentLang,
                                            guestNickname,
                                            userNickname,
                                            title
                                        });
                                    }}
                                >
                                    { mode === HygiaMode.UPDATE ? "Modifier le guichet" : "Créer un guichet" }
                                </Button>
                                {
                                    mode === HygiaMode.UPDATE && (
                                        <>
                                            <Button 
                                                fullWidth 
                                                type="button" 
                                                className={`shadow-2 mb-4 ${styles.btn}`} 
                                                onClick={() => onResetTokens && onResetTokens()}
                                            >
                                                Regénérer les tokens
                                            </Button>
                                            <Button 
                                                fullWidth 
                                                type="button" 
                                                className={`shadow-2 mb-4 ${styles.btn}`} 
                                                onClick={() => onDownloadConso && onDownloadConso()}
                                            >
                                                Télécharger la consommation
                                            </Button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default HygiaEdit;