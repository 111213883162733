import React, {useState } from 'react';
import { default as MuiAlert } from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const Alert = (props) => {
    const [open, setOpen] = useState(true);
    return(
        <Collapse in={props.alert.open}>
                <MuiAlert
                    severity={props.alert.severity || 'success'}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                props.close();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {props.alert.message}
                </MuiAlert>
            </Collapse>
    )
}

export default Alert;