import React, {useEffect, useRef, useState} from 'react';
import UserService from '../../http-services/user.service';
import Alert from "../../components/Alert/Alert";
import BackdropLoader from "../../components/BackdropLoader/Backdrop";
import {i18n} from "../../utils/i18n";
import {default as MaterialTable} from "../../components/MaterialTable/MaterialTable";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import LicenseService from "../../http-services/license.service";
import { useHistory } from "react-router-dom";

import _ from "lodash";

const UsersData = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    
    const [alert, setAlert] = useState({});
    const close = () =>{
        setAlert({...alert, open: false})
    }

    const [licenses, setLicenses] = useState([]);
    
    const getData = () => {
        // load licenses from DB
        LicenseService.getLicenses()
            .then(response => {
                setLicenses(response.data);
            })
            .catch(error => {
                if (error.response) {
                    setAlert({ severity: 'error', message: error.response.data.message, open: true });
                    // alertRef.current.toggle('error', error.response.data.message);
                } else {
                    setAlert({ severity: 'error', message: error.toString(), open: true });
                    // alertRef.current.toggle('error', error.toString());
                }
            });
        setLoading(false);
    }
    // get all users from DB
    const getUsers = (callback) => {
        setLoading(true);
        UserService.getUsers()
            .then(response => {
                setUsers(response.data);
                setLoading(false);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                if (error.response) {
                    setAlert({ severity: 'error', message: error.response.data.message, open: true });
                    // alertRef.current.toggle('error', error.response.data.message);
                }
                else{
                    setAlert({ severity: 'error', message: error.toString(), open: true });
                }
                // alertRef.current.toggle('error', error.toString());
                setLoading(false);
            })
    }

    useEffect(() => {
        getUsers();
        getData();
    }, []);

    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    const renderLicenseColumn = (props) => {
        if (_.isEmpty(props.licenses)){
            return '/';
        }

        let lastLicense = _.last(props.licenses);
        return (
            lastLicense.license.title
        );
    }

    

    const licenseColumnEditComponent = (props) => {
        return (
            <Select fullWidth onChange={e => props.onChange(e.target.value)} defaultValue={props.value} value={props.value}>
                {licenses.map(license => (
                    <MenuItem key={license._id} value={license._id}>{license.title}</MenuItem>
                ))}
            </Select>
        )
    }

    const onRowClick = (e,rowData) =>{
        history.push(`/show-user/${rowData._id}`)
    }

    const columns = [
        { field: 'first_name', title: i18n._("First Name"), minWidth: 120 },
        { field: 'last_name', title: i18n._("Last Name"), minWidth: 120 },
        { field: 'email', title: i18n._("E-Mail"), minWidth: 200, align: 'left' },
        { field: 'license', title: i18n._("License"), minWidth: 100, align: 'right', render: renderLicenseColumn, editComponent: licenseColumnEditComponent},
    ];

  
    return (
        <>
            <Alert alert={alert} close={close}/>
            <BackdropLoader open={loading}/>
            <MaterialTable
                onRowClick={onRowClick}
                columns={columns}
                data={users}
                title={i18n._("Users")}
                editable={{
                    onRowAdd: (newData) => new Promise((resolve) => {
                        UserService.createUser(newData)
                            .then(() => {
                                getUsers(() => {
                                    setAlert({ severity: 'success', message: i18n._("Account Created") + ` - ${newData.email}` });
                                });
                            })
                            .catch(error => {
                                if (error.response) {
                                    setAlert({ severity: 'error', message:  error.response.data.message });
                                }
                            })
                            resolve();
                    }),
                    // onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                    //     UserService.updateUser(newData)
                    //         .then(() => {
                    //             getUsers(() => {
                    //                 resolve();
                    //                 alertRef.current.toggle('success', i18n._("Account Updated") + ` - ${newData.email}`);
                    //             });
                    //         })
                    //         .catch(error => {
                    //             if (error.response) {
                    //                 alertRef.current.toggle('error', error.response.data.message);
                    //             }
                    //         })
                    // }),
                    onRowDelete: (oldData) => new Promise((resolve) => {
                        UserService.deleteUser(oldData)
                            .then(response => {
                                getUsers(() => {
                                    resolve();
                                    setAlert({ severity: 'success', message:  i18n._("Account Deleted") });
                                    // alertRef.current.toggle('success', i18n._("Account Deleted"));
                                });
                            })
                            .catch(error => {
                                if (error.response) {
                                    setAlert({ severity: 'error', message:  error.response.data.message });
                                    // alertRef.current.toggle('error', error.response.data.message);
                                }
                            })
                    })
                }}
            />
        </>
    );
}

export default UsersData;