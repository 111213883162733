import http from './http-common';

class StatsService {
    
    async get(url){
        return http.get(url);
    }

    async getStats({start,end}) {
        return http.get(`/admin/stats?start_date=${start}&end_date=${end}`);
    }

    async getSessions({start,end}) {
        return http.get(`/admin/sessions?start_date=${start}&end_date=${end}`);
    }

    async downloadConso(filter) {
        if(filter && filter.start && filter.end)
            return http.get(`/admin/sessions/download-conso?start_date=${filter.start}&end_date=${filter.end}`);
        else
            return http.get(`/admin/sessions/download-conso`);
    }

    async downloadFile(data) {
        return http.get(`/session/${data._id}/download`,{
            method: 'GET',
            responseType: 'blob', // important
        });
    }

    async deleteFile(data) {
        return http.get(`/session/${data._id}/delete-transcription`,{
            method: 'GET'
        });
    }
}

export default new StatsService();
